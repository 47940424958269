export const logo = require('@/assets/brands/OUBARI.png');
export const drawerLogos = {
    normal: require('@/assets/brands/Untitled-1.png'),
    mini: require('@/assets/brands/Untitled-1.png')
};

export const colors = {
    // primary:  '#253c99',  
    // #0b5b6f //
    primary: '#253c99',  
    // '#', // #253c99  #014a97  #  041943 
    primaryLight: '#ccddfd',
    //  '#', // #b2c1ff #c0dfff # a0ecff
    // secondary: '#5371ad', // #f57f20 // #f57f20 #5371ad
    secondary: '#85ba00',
    lightBlue:  '#103aa2', // #0996b9 # # 0996b9 85b901
}